@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Belleza&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --greenColor: #2AA100
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}

* {
    font-family: 'Belleza', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    box-sizing: border-box;
}

button {
  border: none;
  outline: none;
  background: none
}

a:link {
  color: #000
}

a:visited {
  color: #000;
}

.greenButton {
  width: 120px;
  height: 40px;
  background-color: var(--greenColor);
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #000;
  font-weight: bold;
  font-size: 16px
}

  .greenButton:hover {
    background-color: #32ba02;
  }

  .greenButton:active {
    box-shadow: 0px 0px 0px #000
  }

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
}

.navBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-bottom: 0.5px solid #a2a2a2;
}

  .navBar__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 80px;
    padding: 20px;
  }

  .navBar__logo {
    width: 200px;
  }

  .navBar__links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navBar__link {
    font-weight: bold;
    font-size: 16px;
    margin-left: 15px;
  }

  .navBar__link:hover {
    color: var(--greenColor)
  }

  .navBar__container_mobileMenu {
    display: none
  }

  .navBar__container_mobileMenu_open {
    display: none
  }

  .navBar__container_mobileMenu_close {
    display: none
  }

.homeContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background-color: white;
  border-right: 0.5px dotted #000;
  border-left: 0.5px dotted #000;
  padding: 10px;
  padding-top: 40px;
}

  .homeContainer__about {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 100px;
    width: 50%;
    min-width: 300px;
  }

  .homeContainer__img {
    width: 450px;
    border-radius: 6px;
  }

  .homeContainer__buttonArea {
    display: flex;
    flex-direction: row;
    margin-top: 20px
  }

.shopContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  padding-right: 10px;
  background-color: white;
  border-right: 0.5px dotted #000;
  border-left: 0.5px dotted #000;
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: scroll;
}

  .shopContainer__categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 0.5px dotted #000;
    padding-bottom: 10px;
    width: 220px;
    height: 100%
  }

    .shopContainer__categories_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: var(--greenColor);
      height: 60px;
      width: 220px;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }

    .shopContainer__categories_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 80%;
      height: 40px;
      border-bottom: 0.5px dashed #000;
      padding: 5px
    }

    .shopContainer__categories_item_selected {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 80%;
      height: 40px;
      border-bottom: 0.5px dashed #000;
      padding: 5px;
      background-color: #e4fcde
    }

    .shopContainer__categories_item:hover {
      background-color: #e4fcde
    }

  .shopContainer__productArea {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    min-height: 900px;
    margin-bottom: 100px;
  }

  .shopContainer__productArea_item {
    display: flex;
    flex-direction: row;
    height: 120px;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.43);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
  }

  .shopContainer__productArea_item:hover {
    display: flex;
    flex-direction: row;
    height: 120px;
    box-shadow: 0px 2px 5px 2px rgba(42,161,0,0.58);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
  }

    .moreImages {
      display: flex;
      position: absolute;
      z-index: 20
    }

    .moreImages_img {
      display: flex;
      width: 32px;
      height: 32px;
    }

    .shopContainer__productArea_item_img {
      height: 110px;
      object-fit:contain;
      border-radius: 10px
    }

    .shopContainer__productArea_item_description {
      display: flex;
      width: auto;
      overflow-x: scroll;
      flex-direction: column;
      margin-left: 10px
    }

    .shopContainer__productArea_item_description_boldText {
      font-size: 16px;
      font-weight: bold;
    }

     .shopContainer__productArea_item_description_boldText.under {
       text-decoration: underline;
     }

    .shopContainer__productArea_item_description_normalText {
      font-size: 14px;
      font-weight: normal;
    }

    .shopContainer__productArea_item_description_categoryText {
      font-size: 12px;
      font-weight: normal;
    }

    .shopContainer__productArea_item_description_priceText {
      font-size: 14px;
      font-weight: bold;
      color: red
    }

    .sortRow {
      display: flex;
      flex-direction: row;
      height: 40px;
    }


.contactsContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  background-color: white;
  border-right: 0.5px dotted #000;
  border-left: 0.5px dotted #000;
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: scroll;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 100px;
}

  .contactsContainer__map {
    display: flex;
    flex-direction: column;
    width: 40vw;
    min-width: 300px;
    height: 250px;
    border: none;
    border-radius: 18px;
    box-shadow: 1px 1px 5px 1px var(--greenColor);
  }

  .contactsContainer__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 25vw;
    margin-left: 20px;
  }

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--greenColor);
}

  .footer__container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }

  .footer__contacts {
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 12px
  }

  .footer__social {
    width: 32px;
    height: 32px
  }

@media only screen and (max-width: 400px) {
  .homeContainer__img {
    width: 10px;
    display: none
  }
  .homeContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    background-color: white;
    border-right: 0.5px dotted #000;
    border-left: 0.5px dotted #000;
    padding: 10px;
    padding-top: 40px;

  }

  .homeContainer__about {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 80%;
    height: 100%;
    min-width: 300px;
    overflow: none;
    overflow-x: none;
    overflow-y: none;

  }

  .shopContainer__productArea_item {
    display: flex;
    flex-direction: row;
    height: auto;
    min-height: 120px;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.43);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
  }

  .shopContainer__productArea_item:hover {
    display: flex;
    flex-direction: row;
    height: auto;
    min-height: 120px;
    box-shadow: 0px 2px 5px 2px rgba(42,161,0,0.58);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
  }
  .shopContainer__productArea {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    min-height: 900px;
    height: auto;
    margin-bottom: 180px;
    padding-bottom: 200px;
  }

  .contactsContainer__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 25vw;
    margin-left: 20px;
    padding-bottom: 80px;
  }

  .contactsContainer {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    background-color: white;
    border-right: 0.5px dotted #000;
    border-left: 0.5px dotted #000;
    overflow: scroll;
    overflow-x: scroll;
    overflow-y: scroll;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 180px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: var(--greenColor);
  }
}
@media only screen and (min-width: 201px) and (max-width: 950px) {

  .navBar__links {
    display: none;
    flex-direction: row;
    align-items: center;
    width: 100%
  }

  .navBar__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    height: 60px;
  }

  .navBar__container_mobileMenu {
    display: flex;
  }

  .navBar__container_mobileMenu_img {
    display: flex;
    width: 32px;
    height: 32px
  }

  .navBar__container_mobileMenu_links {
    display: flex;
    flex-direction: row;
    min-width: 80%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
  }

  .homeContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    background-color: white;
    border-right: 0.5px dotted #000;
    border-left: 0.5px dotted #000;
    padding: 10px;
    padding-top: 40px;


  }

    .homeContainer__about {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-right: 30px;
      padding-bottom: 10px;
      width: 80%;
      height: 100%;
      min-width: 300px;
      overflow: none;
      overflow-x: none;
      overflow-y: none;

    }

    .homeContainer__imgBox {
      width: 80%;
      position: relative;
      bottom: 10px
    }

    .homeContainer__img {
      position: relative;
      width: 90%;
      border-radius: 6px;
      align-self: center;
      padding-bottom: 120px;
    }

    .homeContainer__buttonArea {
      display: flex;
      flex-direction: row;
      margin-top: 20px
    }

  .shopContainer {
    flex-direction: column;
    padding-right: 0px;
  }

    .shopContainer__categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.5px dotted #000;
      width: 100%;
      background-color: var(--greenColor);
      min-height: 80px;
      height: 80px;
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: 0px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
    }

      .shopContainer__categories::-webkit-scrollbar {
        display: none
      }

        .shopContainer__categories_header {
          display: none
        }

        .shopContainer__categories_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: center;
          min-width: 100px;
          height: 80px;
          border-right: 0.5px dashed #000;
          border-bottom: 0px dashed #000;
          color: white;
          padding-bottom: 5px
        }

        .shopContainer__categories_item_selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: center;
          min-width: 100px;
          height: 80px;
          border-right: 0.5px dashed #000;
          border-bottom: 0px dashed #000;
          color: black;
          padding-bottom: 5px;
          background-color: #e4fcde;
        }

        .shopContainer__categories_item:hover {
          background-color: #e4fcde;
          color: var(--greenColor)
        }

}

@media only screen and (max-width: 880px) {
  .contactsContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    background-color: white;
    border-right: 0.5px dotted #000;
    border-left: 0.5px dotted #000;
    overflow: scroll;
    overflow-x: scroll;
    overflow-y: scroll;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 120px;
  }

    .contactsContainer__map {
      display: flex;
      flex-direction: column;
      width: 40vw;
      min-width: 250px;
      height: 250px;
      border: none;
      border-radius: 18px;
      box-shadow: 1px 1px 5px 1px var(--greenColor);
    }

    .contactsContainer__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 10px;
      padding-bottom: 100px;
      margin-top: 30px;
      margin-bottom: 150px
    }
}

